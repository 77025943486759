<!-- <ngx-header *ngIf="!loginUser"></ngx-header> -->
<!-- owl-carousel Banner Start -->
<section class="pt-0 gen-section-padding-2" *ngIf="isTileview && !isCustomview" class="gen-breadcrumb"
   style="background-image: url('../../../assets/images/backgrounds/account.jpg');">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <nav aria-label="breadcrumb" class="pb-3">
               <ngx-breadcrumb></ngx-breadcrumb>
            </nav>
            <div class="grid-container">
               <div class="grid-item" *ngFor="let tileview of tileviews">
                  <span *ngIf="tileview.forNotification && totalUnseenPrescription>0"
                     class="prescription-number-holder"><a href="javascript:void(0)"
                        (click)="gotoNotification()">{{totalUnseenPrescription}}</a></span>
                  <a href="javascript:void(0)" [routerLink]="[tileview.routeUrl]">
                     <img [src]="tileview.thumbnailUrl" alt="..."></a>
                  <br>
                  <span class="px-1">{{tileview.label}}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<div *ngIf="isCustomview" class="gen-breadcrumb pb-2 banner-padding"
   style="background-image: url('../../../assets/images/background/CHUM-Home-Banner-Image.png');">
   <div class="container">
      <div class="row align-items-center">
         <!-- <div class="col-3 col-lg-2">
            <img class="banner-icon-width" src="../../../assets/images/background/transparent-hopiflix-arrow.png" alt="...">
        </div> -->
         <div class="col-12">
            <nav aria-label="breadcrumb">
               <div class="gen-breadcrumb-title">
                  <h1 style="color:#02ff00;" i18n="@@landing.home">HOME</h1>
               </div>
               <ngx-breadcrumb></ngx-breadcrumb>
            </nav>
         </div>
         <!-- <div class="col-3 col-lg-2">
            <img class="banner-icon-width" src="../../../assets/images/background/transparent-CHUM-logo.png" alt="...">
        </div> -->
      </div>
   </div>
</div>
<section class="pt-0 gen-section-padding-2" *ngIf="isCustomview" class="gen-breadcrumb"
   style="background-image: url('../../../assets/images/background/account.jpg');">
   <div class="container pt-4">
      <div class="row">
         <div class="col-lg-12">
            <div class="custom-grid-container">
               <div class="grid-item" *ngFor="let customView of customViews">
                  <a href="javascript:void(0)" (click)="openRouteUrl(customView.routeUrl,customView.isOnline)">
                     <img [src]="customView.thumbnailUrl" alt="..."></a>
                  <br>
                  <span class="px-1">{{customView.label}}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<div class="div-padding" *ngIf="!isCustomview && !isForCustomVideo">
   <section class="pb-0" [ngClass]="isTileview?'pt-0':'section-top-padding'">
      <div class="container-fluid px-0">
         <div class="row no-gutters">
            <div class="col-12">
               <div class="gen-banner-movies banner-style-2 carousel">
                  <owl-carousel-o [options]="customOptions">
                     <ng-container>
                        <ng-template carouselSlide>
                           <div class="item" style="background: url('../../../assets/images/preview/Edu.jpg')">
                              <div class="gen-movie-contain-style-2 h-100">
                                 <div class="container h-100">
                                    <div class="row flex-row-reverse align-items-center h-100">
                                       <div class="col-xl-6">
                                          <div class="gen-front-image">
                                             <img src="../../../assets/images/preview/Edu.jpg"
                                                alt="owl-carousel-banner-image">
                                             <a [routerLink]="['/home/video-viewer/515776088/5bd69a6e37e2235bfa1b80ff8647f2d02f62e0c5/139/false/0000/0000/false']"
                                                class="playBut popup-youtube popup-vimeo popup-gmaps">
                                                <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  -->
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="213.7px"
                                                   height="213.7px" viewBox="0 0 213.7 213.7"
                                                   enable-background="new 0 0 213.7 213.7" xml:space="preserve">
                                                   <polygon class="triangle" id="XMLID_17_" fill="none" stroke-width="7"
                                                      stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-miterlimit="10" points="
                                                                73.5,62.5 148.5,105.8 73.5,149.1 "></polygon>
                                                   <circle class="circle" id="XMLID_18_" fill="none" stroke-width="7"
                                                      stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3">
                                                   </circle>
                                                </svg>
                                                <span i18n="@@landing.watchTrailer">Watch Trailer</span>
                                             </a>
                                          </div>
                                       </div>
                                       <div class="col-xl-6">
                                          <div class="gen-tag-line"><span i18n="@@landing.newvideo">New Video</span>
                                          </div>
                                          <div class="gen-movie-info">
                                             <h3 i18n="@@landing.part1">Preventing infection after surgery</h3>
                                          </div>
                                          <div class="gen-movie-meta-holder">
                                             <ul class="gen-meta-after-title">
                                                <li class="gen-sen-rating">
                                                   <span i18n="@@landing.pg">
                                                      PG-18</span>
                                                </li>
                                                <li><i aria-hidden="true" class="fas fa-hospital fa-2x"></i>
                                                </li>
                                             </ul>
                                             <p i18n="@@landing.message">Watch this short video to learn simple ways to
                                                prevent infections after
                                                surgery. The video discusses taking care of your wounds, keeping them
                                                clean, and recognizing signs of infection. Get the basic information you
                                                need after your surgery for a speedy recovery.
                                             </p>
                                             <div class="gen-meta-info">
                                                <ul class="gen-meta-after-excerpt">
                                                   <li>
                                                      <strong i18n="@@landing.presentation">Presentation :</strong>
                                                      Milner Fenwick
                                                   </li>
                                                   <li>
                                                      <strong i18n="@@landing.genre">Genre :</strong>
                                                      <span>
                                                         <a href="javascript:void(0)" i18n="@@landing.medical">
                                                            Health Education,</a>
                                                      </span>
                                                      <span>
                                                         <a href="javascript:void(0)" i18n="@@landing.surgery">
                                                            Surgery </a>
                                                      </span>
                                                   </li>
                                                </ul>
                                             </div>
                                          </div>
                                          <div class="gen-movie-action">
                                             <div class="gen-btn-container">
                                                <a [routerLink]="['/home/video-viewer/515776088/5bd69a6e37e2235bfa1b80ff8647f2d02f62e0c5/139/false/0000/0000/false']"
                                                   class="gen-button .gen-button-dark">
                                                   <i aria-hidden="true" class="fas fa-play"></i> <span class="text"
                                                      i18n="@@landing.playNow">Play
                                                      Now</span>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                     </ng-container>
                     <ng-container>
                        <ng-template carouselSlide>
                           <div class="item" style="background: url('../../../assets/images/preview/Wonder.jpg')">
                              <div class="gen-movie-contain-style-2 h-100">
                                 <div class="container h-100">
                                    <div class="row flex-row-reverse align-items-center h-100">
                                       <div class="col-xl-6">
                                          <div class="gen-front-image">
                                             <img class="carousel-image" src="../../../assets/images/preview/Wonder.jpg"
                                                alt="owl-carousel-banner-image">
                                             <a [routerLink]="hasEnCulture? ['/home/video-viewer/494120072/008afd993ae6b2ae63bef03dbb19de240ae0433b/165/false/0000/0000/false']:['/home/video-viewer/494120126/02688300843983cb87d5db8f1dbb3ad28182095c/165/false/0000/0000/false']"
                                                class="playBut popup-youtube popup-vimeo popup-gmaps">

                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="213.7px"
                                                   height="213.7px" viewBox="0 0 213.7 213.7"
                                                   enable-background="new 0 0 213.7 213.7" xml:space="preserve">
                                                   <polygon class="triangle" id="XMLID_17_" fill="none" stroke-width="7"
                                                      stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-miterlimit="10" points="
                                                          73.5,62.5 148.5,105.8 73.5,149.1 "></polygon>
                                                   <circle class="circle" id="XMLID_18_" fill="none" stroke-width="7"
                                                      stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3">
                                                   </circle>
                                                </svg>

                                                <span i18n="@@landing.watchTrailer">Watch Trailer</span>
                                             </a>
                                          </div>
                                       </div>
                                       <div class="col-xl-6">
                                          <div class="tag-line-music"> <span i18n="@@landing.new">NEW MOVIE</span></div>
                                          <div class="gen-movie-info">
                                             <h3 i18n="@@landing.part2">Wonder</h3>
                                          </div>
                                          <div class="gen-movie-meta-holder">
                                             <ul class="gen-meta-after-title">
                                                <li class="gen-sen-rating"><span>G</span></li>
                                                <li><i aria-hidden="true" class="fa fa-play-circle"
                                                      style="color:#ff6600"></i>
                                                </li>
                                             </ul>
                                             <p i18n="@@landing.message2">Based on the New York Times bestseller, WONDER
                                                tells the incredibly
                                                inspiring and heartwarming story of August Pullman. Born with facial
                                                differences that, up until now, have prevented him from going to a
                                                mainstream school, Auggie becomes the most unlikely of heroes when he
                                                enters the local fifth grade. As his family, his new classmates, and the
                                                larger community all struggle to discover their compassion and
                                                acceptance...
                                             </p>
                                             <div class="gen-meta-info">
                                                <ul class="gen-meta-after-excerpt">
                                                   <li>
                                                      <span style="color:#ff6600" i18n="@@landing.genre">Genre :</span>
                                                      <span>
                                                         <a href="javascript:void(0)" i18n="@@landing.drama">
                                                            Drama</a>
                                                      </span>
                                                   </li>
                                                </ul>
                                             </div>
                                          </div>
                                          <div class="gen-movie-action">
                                             <div class="gen-btn-container">
                                                <a [routerLink]="hasEnCulture? ['/home/video-viewer/494120072/008afd993ae6b2ae63bef03dbb19de240ae0433b/165/false/0000/0000/false']:['/home/video-viewer/494120126/02688300843983cb87d5db8f1dbb3ad28182095c/165/false/0000/0000/false']"
                                                   class="gen-button .gen-button-dark musicbg" style="color:#ffffff">
                                                   <i aria-hidden="true" class="fas fa-play"></i> <span class="text"
                                                      i18n="@@landing.playNow">Play
                                                      Now</span>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-template></ng-container>
                  </owl-carousel-o>
               </div>
            </div>
         </div>
      </div>
   </section>
</div>
<ngx-custom-video-card *ngIf="!isCustomview && isForCustomVideo" [customOptions]="customOptions" [isTileview]="isTileview"  [customVideoList]="customVideoList"></ngx-custom-video-card>
<!-- owl-carousel Banner End -->
<div class="div-padding" *ngIf="!isTileview && !isCustomview">
   <!-- owl-carousel Videos Section-1 Start -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title iptv" i18n="@@landing.iptv">IPTV</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a (click)="redirectToIptv()" class="gen-button gen-button-flat iptvbg">
                        <span class="text" i18n="@@landing.moreIptv">More IPTV</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2">
                  <ngx-iptv-card [IptvChannels]="IptvChannels" [isForCarousel]="true"></ngx-iptv-card>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-1 End -->


   <!-- owl-carousel Videos Section-2 Start -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title movie" i18n="@@landing.movies">Movies</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a [routerLink]="['/home/infotainment/movie']" class="gen-button gen-button-flat movie-button">
                        <span class="text" i18n="@@landing.moreMovies">More Movies</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>

         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2">
                  <ngx-movie-card [movies]="movies" [isForCarousel]="true"></ngx-movie-card>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-2 End -->


   <!-- owl-carousel Videos Section-3 Start -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title music" i18n="@@landing.music">Music</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a [routerLink]="['/home/infotainment/music']" class="gen-button gen-button-flat musicbg">
                        <span class="text" i18n="@@landing.moreMusic">More Music</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2">
                  <ngx-music-card [musicAlbums]="musicAlbums" [isForCarousel]="true"></ngx-music-card>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-3 End -->

   <!-- owl-carousel Videos Section-4 Start -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title radio" i18n="@@landing.radio">Radio</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a [routerLink]="['/home/infotainment/radio']" class="gen-button gen-button-flat radiobg">
                        <span class="text" i18n="@@landing.moreRadio">More Radio</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2">
                  <ngx-radio-card [radioChannels]="radioChannels" [isForCarousel]="true"></ngx-radio-card>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-4 End -->

   <!-- owl-carousel Videos Section-5 Start -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title audio" i18n="@@landing.audioBook">Audio Books</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a [routerLink]="['/home/infotainment/audio-book']" class="gen-button gen-button-flat audiobg">
                        <span class="text" i18n="@@landing.moreAudioBook">More Audio Books</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2">
                  <ngx-audio-book-card [audiobooks]="audiobooks" [isForCarousel]="true"></ngx-audio-book-card>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-5 End -->

   <!-- owl-carousel Videos Section-6 Start -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title relaxation" i18n="@@landing.relaxation">Relaxation</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a [routerLink]="['/home/infotainment/relaxation']"
                        class="gen-button gen-button-flat relaxationbg">
                        <span class="text" i18n="@@landing.moreRelaxation">More Relaxation</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2" *ngIf="this.relaxationMovies.length>0">
                  <ngx-relaxation-card [relaxationMovies]="relaxationMovies"
                     [isForCarousel]="true"></ngx-relaxation-card>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-6 End -->

   <!-- owl-carousel Videos Section-7 Start -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title kids" style="font-size:34px;">
                  <span *ngIf="!hasEnCulture">
                     <span class="red">E</span><span class="blue">n</span><span class="green">f</span><span
                        class="pink">a</span><span class="orange">n</span><span class="white">t</span><span
                        class="yellow">s</span>
                  </span>
                  <span *ngIf="hasEnCulture">
                     <span class="red">K</span><span class="blue">i</span><span class="green">d</span><span
                        class="yellow">s</span>
                  </span>
               </h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a [routerLink]="['/home/infotainment/kids']" class="gen-button gen-button-flat kidsbg">
                        <span class="text" i18n="@@landing.moreKids">More Kids</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2">
                  <ngx-kids-card [kidsVideos]="kidsVideos" [isForCarousel]="true"></ngx-kids-card>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-9 End -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title" style="color:#3f9fff" i18n="@@landing.health">InfoHealth</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a [routerLink]="['/home/info-health/health-education']" class="gen-button gen-button-flat">
                        <span class="text" i18n="@@landing.moreHealth">More InfoHealth</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2">
                  <ngx-health-education-card [healthEducationMedias]="healthEducationMedias" [isForCarousel]="true">
                  </ngx-health-education-card>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-10 End -->

   <!-- owl-carousel Videos Section-11 Start -->
   <section class="pt-0 gen-section-padding-2">
      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title" style="color:#3f9fff" i18n="@@landing.hospital">Hospital Info</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-md-inline-block">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                     <a [routerLink]="['/home/hospital-Info']" class="gen-button gen-button-flat">
                        <span class="text" i18n="@@landing.more-hospital" i18n="@@landing.moreHospital">More Hospital
                           Info</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">
               <div class="gen-style-2">
                  <ngx-hospital-info-card [hospitalInfos]="hospitalInfos"
                     [isForCarousel]="true"></ngx-hospital-info-card>
               </div>
            </div>
         </div>
      </div>
   </section>
</div>
<!-- owl-carousel Videos Section-12 End -->
<!-- <ngx-footer *ngIf="!loginUser"></ngx-footer> -->