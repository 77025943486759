import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';



import {
  FooterComponent,
  HeaderComponent,
  PaginationComponent,
  SearchInputComponent,
  SignInComponent,
  SignUpComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe
} from './pipes';
import {
  NoColumnsLayoutComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { DARK_THEME } from './styles/theme.dark';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbLayoutModule, NbMenuModule, NbUserModule, NbActionsModule, NbSearchModule, NbSidebarModule, NbContextMenuModule, NbButtonModule, NbSelectModule, NbIconModule, NbCardModule, NbThemeModule, NbListModule, NbInfiniteListDirective, NbTreeGridModule } from '@nebular/theme';
import { IconsComponent } from './components/icons/icons.component';
import { PdfSrcSafePipe } from './pipes/pdfSrcSafePipe.pipe';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FormsModule } from '@angular/forms';
import { MovieCardComponent } from './components/cards/movie-card/movie-card.component';
import { MusicCardComponent } from './components/cards/music-card/music-card.component';
import { AudioBookCardComponent } from './components/cards/audio-book-card/audio-book-card.component';
import { RelaxationCardComponent } from './components/cards/relaxation-card/relaxation-card.component';
import { RadioCardComponent } from './components/cards/radio-card/radio-card.component';
import { IptvCardComponent } from './components/cards/iptv-card/iptv-card.component';
import { KidsCardComponent } from './components/cards/kids-card/kids-card.component';
import { HopitvCardComponent } from './components/cards/hopitv-card/hopitv-card.component';
import { PatientInfoCardComponent } from './components/cards/patient-info-card/patient-info-card.component';
import { HospitalInfoCardComponent } from './components/cards/hospital-info-card/hospital-info-card.component';
import { HealthEducationCardComponent } from './components/cards/health-education-card/health-education-card.component';
import { GameCardComponent } from './components/cards/game-card/game-card.component';
import { UriPipe } from './pipes/uri.pipe';
import { CarouselDirective } from './directives/carousel.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CustomVideoCardComponent } from './components/cards/custom-video-card/custom-video-card.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  FormsModule,
  NbListModule,
  NbTreeGridModule,
  CarouselModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  NoColumnsLayoutComponent,
  SignInComponent,
  SignUpComponent,
  PaginationComponent,
  IconsComponent,
  BreadcrumbComponent,
  MovieCardComponent,
  MusicCardComponent,
  AudioBookCardComponent,
  RelaxationCardComponent,
  RadioCardComponent,
  IptvCardComponent,
  KidsCardComponent,
  HopitvCardComponent,
  PatientInfoCardComponent,
  HospitalInfoCardComponent,
  HealthEducationCardComponent,
  GameCardComponent,
  CustomVideoCardComponent
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  PdfSrcSafePipe,
  UriPipe
];
const DIRECTIVES = [
  CarouselDirective
];
const providers = [
  NbThemeModule.forRoot(
    {
      name: 'dark'
    },
    [DEFAULT_THEME, DARK_THEME],
  ).providers || []
]

@NgModule({
  imports: [CommonModule, RouterModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...DIRECTIVES, ...COMPONENTS, NbCardModule],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...providers
      ],
    };
  }
}
