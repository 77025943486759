import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FacilityService } from 'src/app/@core/services/facility.service';
import { PrescriptionManagerService } from 'src/app/@core/services/prescription-manager.service';
import { CommonService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() facilityLogo: any;
  year = new Date().getFullYear();
  facilityChangesSubscription: Subscription;
  hasEnCulture: boolean;
  isFreeFlag: boolean = false;
  isCustomView: boolean = false;
  isForCustomVideo: boolean = false;

  constructor(private commonService: CommonService, private facilityService: FacilityService) {
    // change Facility
    this.facilityChangesSubscription = this.commonService.facilityChanges.subscribe((response) => {
      this.isCustomView = this.commonService.isCustomView;
      this.isFreeFlag = this.commonService.isFreeFacilityFlag;
      const facilityCode = this.commonService.getLSKey("facilityCode");
      this.isForCustomVideo = this.commonService.customVideoFacilityList.find(x => x == facilityCode) ? true : false;
    });
    this.hasEnCulture = this.commonService.getLanguage() === 'en';
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.facilityChangesSubscription.unsubscribe();
  }
}
