import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-custom-video-card',
  templateUrl: './custom-video-card.component.html',
  styleUrls: ['./custom-video-card.component.scss']
})
export class CustomVideoCardComponent implements OnInit {
  @Input() isTileview: boolean = false;
  @Input() customVideoList: any=[];
  @Input() customOptions: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
