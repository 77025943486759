import { Component, OnInit, ViewChild } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EMPTY, forkJoin, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Audiobook } from 'src/app/@core/data/audiobook';
import { IptvChannel } from 'src/app/@core/data/iptvChannel';
import { Movie } from 'src/app/@core/data/movie';
import { RadioChannel } from 'src/app/@core/data/radioChannel';
import { AudiobookService } from 'src/app/@core/services/audiobook.service';
import { IptvService } from 'src/app/@core/services/iptv.service';
import { KidsService } from 'src/app/@core/services/kids.service';
import { MovieService } from 'src/app/@core/services/movie.service';
import { MusicService } from 'src/app/@core/services/music.service';
import { PrescriptionManagerService } from 'src/app/@core/services/prescription-manager.service';
import { RadioService } from 'src/app/@core/services/radio.service';
import { CommonService } from 'src/app/@core/utils';
import { HeaderComponent } from 'src/app/@theme/components';
import { AudioBookCardComponent } from 'src/app/@theme/components/cards/audio-book-card/audio-book-card.component';
import { IptvCardComponent } from 'src/app/@theme/components/cards/iptv-card/iptv-card.component';
import { KidsCardComponent } from 'src/app/@theme/components/cards/kids-card/kids-card.component';
import { RadioCardComponent } from 'src/app/@theme/components/cards/radio-card/radio-card.component';
import { NotificationsComponent } from '../shared/notifications/notifications.component';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var script: any;
@Component({
  selector: 'ngx-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  @ViewChild(IptvCardComponent)
  iptvChild!: IptvCardComponent;
  @ViewChild(AudioBookCardComponent)
  audiobookChild!: AudioBookCardComponent;
  @ViewChild(RadioCardComponent)
  radioChild!: RadioCardComponent;
  movies: Array<Movie> = new Array<Movie>();
  loginUser: boolean = false;
  relaxationMovies: Array<any> = new Array<any>();
  radioChannels: Array<RadioChannel> = new Array<RadioChannel>();
  audiobooks: Array<Audiobook> = new Array<Audiobook>();
  musicAlbums: Array<any> = new Array<any>();
  IptvChannels: Array<IptvChannel> = new Array<IptvChannel>();
  kidsVideos: Array<any> = new Array<any>();
  hopitvVideos: Array<any> = new Array<any>();
  patientInfos: Array<any> = new Array<any>();
  hospitalInfos: Array<any> = new Array<any>();
  healthEducationMedias: Array<any> = new Array<any>()
  facilityChangesSubscription: Subscription;
  folderName: string = "/";
  gameSites: Array<any> = new Array<any>();
  genreId: number = 0;
  orderBy: string = 'title';
  pageIndex: number = 0;
  pageSize: number = 10;
  filters: string = "";
  hasEnCulture: boolean = false;
  customOptions: OwlOptions = {
    rtl: false,
    items: 1,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoWidth: true,
    navSpeed: 700,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
        items: 1,
        nav: true,
        dots: false
      },
      480: {
        items: 1,
        nav: true,
        dots: false
      },
      786: {
        items: 1
      },
      1023: {
        items: 1
      },
      1199: {
        items: 1
      }
    }
  }
  @ViewChild(KidsCardComponent)
  childKidsCard!: KidsCardComponent;
  @ViewChild(IptvCardComponent)
  childIptvCard!: IptvCardComponent;
  @ViewChild(RadioCardComponent)
  childRadioCard!: RadioCardComponent;
  broadcastServiceSubscription!: Subscription;
  isTileview: boolean = false;
  tileviews: Array<any> = [];
  notificationModal: any;
  totalUnseenPrescription: number = 0;
  rebindStatsSubscription!: Subscription;
  customViews: Array<any> = [];
  isCustomview: boolean = false;
  isForCustomVideo: boolean = false;
  customVideoList: Array<any> = [];

  constructor(private commonService: CommonService, private movieService: MovieService,
    private radioService: RadioService, private audiobookService: AudiobookService,
    private musicService: MusicService, private prescriptionManagerService: PrescriptionManagerService,
    private authService: MsalService, private iptvService: IptvService,
    private kidsService: KidsService, private broadcastService: MsalBroadcastService, private router: Router, private nbDialogService: NbDialogService,) {
    this.isTileview = this.commonService.getUserView();
    const account = this.authService.instance.getAllAccounts()[0];
    this.isCustomview = this.commonService.isCustomView;
    // change Facility
    this.facilityChangesSubscription = this.commonService.facilityChanges.subscribe((response) => {
      this.hasEnCulture = this.commonService.getLanguage() === 'en';
      this.getTileviewCards();
      if (response) {
        this.iptvChild?.ngOnDestroy();
        this.audiobookChild?.ngOnDestroy();
        this.radioChild?.ngOnDestroy();
        this.hospitalInfos = this.commonService.getLSKey('hopitalInfos');
        const facilityCode = this.commonService.getLSKey("facilityCode");
        this.isForCustomVideo = this.commonService.customVideoFacilityList.find(x => x == facilityCode) ? true : false;
        if (!this.isCustomview)
          this.getAllData();
        else
        this.getCustomViewCards();
        if (this.isForCustomVideo)
          this.getCustomVideoList();
      }
    });
    if (account)
      this.loginUser = true;
    // this.getRecommendedHopitvVideos();
    // this.getRecommendedPatientInfos();
    //this.getRecommendedGames();
    const movieFilter = { _genres: [{ filterValue: '8', action: 'Include' }], _lang: [{ filterValue: "En", action: "Include" }], _ageratings: [] };
    this.filters = JSON.stringify(movieFilter);
    //rebind prescriptionCount
    this.rebindStatsSubscription = this.commonService.rebindStats.subscribe((res: any) => {
      if (res) {
        const counts = this.commonService.CountPrescriptions();
        this.totalUnseenPrescription = counts.totalUnseenPrescription;
      }
    });
  }

  ngOnInit(): void {
    this.broadcastServiceSubscription = this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result) => {
        const lsVideo = this.commonService.getLSKey('purl');
        if (lsVideo?.key == 'IPTV_TRAILER')
          this.childIptvCard.openIptvChannel(lsVideo.id);
        else if (lsVideo?.key == 'KIDS_TRAILER')
          this.childKidsCard.watchMovie(lsVideo.id);
        else if (lsVideo.key == 'RADIO_TRAILER')
          this.childRadioCard.openRadioPlayer(lsVideo.id);
        this.commonService.deleteLSKey('purl');
      })
    script.loader();
    script.init();
    script.stickyHeader();
  }

  ngOnDestroy() {
    this.facilityChangesSubscription.unsubscribe();
    this.broadcastServiceSubscription?.unsubscribe();
    this.childKidsCard?.userChangeSubscription.unsubscribe();
    this.childIptvCard?.userChangeSubscription?.unsubscribe();
    this.childIptvCard?.closePopup();
    this.childRadioCard?.serviceNotAccessPopup?.close();
    this.commonService.deleteLSKey('purl');
    this.notificationModal?.close();
    this.rebindStatsSubscription?.unsubscribe();
  }

  getAllData() {
    this.movies = [];
    this.relaxationMovies = [];
    this.radioChannels = [];
    this.audiobooks = [];
    this.musicAlbums = [];
    this.kidsVideos = [];
    this.healthEducationMedias = [];
    this.IptvChannels = [];
    const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
    const facilityCode = this.commonService.getLSKey("facilityCode");
    const langCode = this.commonService.getLanguage();
    if (ceFacilityId > 0) {
      forkJoin([
        this.movieService.getRecommendedMovies(ceFacilityId, langCode),
        this.movieService.getRecommendedRelaxationVideos(ceFacilityId),
        this.radioService.getRecommendedRadioChannels(ceFacilityId),
        this.audiobookService.getRecommendedAudiobooks(ceFacilityId),
        this.musicService.getRecommendedMusicAlbums(ceFacilityId),
        this.kidsService.getRecommendedKidsVideos(ceFacilityId, langCode),
        this.prescriptionManagerService.getMediaGroup(facilityCode, this.pageIndex + 1, this.pageSize),
        this.iptvService.getRecommendedIptvChannels(ceFacilityId),
      ]).subscribe((results: any) => {
        if (results[0])
          this.movies = results[0];
        if (results[1])
          this.relaxationMovies = results[1];
        if (results[2])
          this.radioChannels = results[2];
        if (results[3])
          this.audiobooks = results[3];
        if (results[4])
          this.musicAlbums = results[4];
        if (results[5])
          this.kidsVideos = results[5];
        if (results[6]?.data)
          this.healthEducationMedias = results[6].data;
        if (results[7])
          this.IptvChannels = results[7];
      })
    }
  }

  getRecommendedHopitvVideos() {
    this.hopitvVideos = [{
      thumbnailUrl: "../../../assets/images/preview/hopi1.jpg",
      title: "Latest Healthcare News",
      duration: '35mins',
      type: "Health Care Organizations and Associations"
    },
    {
      thumbnailUrl: "../../../assets/images/preview/hopi2.jpg",
      title: "Managing Surgical Wait Times",
      duration: '35mins',
      type: "Surgeries and Procedures During COVID-19"
    },
    {
      thumbnailUrl: "../../../assets/images/preview/hopi3.jpg",
      title: "HopiTV Title",
      duration: '35mins',
      type: "Surgery"
    },
    {
      thumbnailUrl: "../../../assets/images/preview/hopi4.jpg",
      title: "HopiTV Title",
      duration: '35mins',
      type: "Surgery"
    },
    {
      thumbnailUrl: "../../../assets/images/preview/hopi5.jpg",
      title: "HopiTV Title",
      duration: '35mins',
      type: "Surgery"
    }
    ]
  }

  getRecommendedPatientInfos() {
    this.patientInfos = [{
      thumbnailUrl: "../../../assets/images/preview/patient2.jpg",
      title: "Prescribed Video",
      description: "View your prescribed videos !",
      url: '/home/prescriptions/prescribed-video'
    },
    {
      thumbnailUrl: "../../../assets/images/preview/patient3.jpg",
      title: "Follow-up Questionnaires",
      description: "Answer your follow-up questionnaires !",
      url: '/home/prescriptions/followup'
    },
    {
      thumbnailUrl: "../../../assets/images/preview/patient4.jpg",
      title: "Prescribed Survey",
      description: "Answer your prescribed survey",
      url: '/home/prescriptions/survey'
    },
    {
      thumbnailUrl: "../../../assets/images/pdf-icon.png",
      title: "Prescribed Document",
      description: "View your prescribed document !",
      url: '/home/prescriptions/document'
    }
    ]
  }

  getRecommendedGames() {
    this.gameSites = [{
      label: 'Game Title',
      thumbnailUrl: "frin-250.png"
    }, {
      label: 'Frin',
      thumbnailUrl: "frin-250.png"
    }, {
      label: 'Game Title',
      thumbnailUrl: "frin-250.png"
    }, {
      label: 'Game Title',
      thumbnailUrl: "frin-250.png"
    }, {
      label: 'Game Title',
      thumbnailUrl: "frin-250.png"
    }]
  }

  getTileviewCards() {
    this.tileviews = [{
      label: this.hasEnCulture ? 'Infotainment' : 'DIVERTISSEMENT',
      thumbnailUrl: "../../../assets/images/tiles/infotainment.png",
      routeUrl: '/home/tileview/infotainment',
      visible: true
    }, {
      label: this.hasEnCulture ? 'My Playlist' : 'Ma liste',
      thumbnailUrl: "../../../assets/images/tiles/my-playlist-option.png",
      routeUrl: '/home/tileview/my-playlist',
      visible: !this.commonService.isFreeFacilityFlag
    }, {
      label: this.hasEnCulture ? 'Communications' : 'Communications',
      thumbnailUrl: "../../../assets/images/tiles/communications.png",
      routeUrl: '/home/tileview/communications',
      visible: this.commonService.isHopiflixCommunication
    }, {
      label: this.hasEnCulture ? 'InfoHealth' : 'InfoSanté',
      thumbnailUrl: "../../../assets/images/tiles/info-health.png",
      routeUrl: '/home/tileview/info-health',
      visible: true
    },
    {
      label: this.hasEnCulture ? 'Prescriptions' : 'Prescriptions',
      thumbnailUrl: "../../../assets/images/tiles/prescriptions.png",
      routeUrl: '/home/tileview/prescriptions',
      forNotification: true,
      visible: (!this.commonService.isFreeFacilityFlag && this.commonService.isHopiflixPrescription)
    },
    {
      label: this.hasEnCulture ? 'Hospital Info' : 'Information Hôpital',
      thumbnailUrl: "../../../assets/images/tiles/hospital-info.png",
      routeUrl: '/home/tileview/hospital-Info',
      visible: true
    },
    {
      label: this.hasEnCulture ? 'Room Services' : 'Services de chambres',
      thumbnailUrl: "../../../assets/images/tiles/concierge-service.png",
      routeUrl: '/home/tileview/room-service',
      visible: environment.demoModeEnabled
    },
    {
      label: this.hasEnCulture ? 'Call Centre' : "Centre d'appel",
      thumbnailUrl: "../../../assets/images/tiles/call-centre.png",
      routeUrl: '/home/tileview/call-centre',
      visible: true
    },
    {
      label: this.hasEnCulture ? 'My Account' : 'Mon compte',
      thumbnailUrl: "../../../assets/images/tiles/my-account.png",
      routeUrl: '/home/tileview/account',
      visible: !this.commonService.isFreeFacilityFlag
    }];
    this.tileviews = this.tileviews.filter(x => x.visible == true);
  }
  
  getCustomViewCards() {
    this.customViews = [{
      label: this.hasEnCulture ? 'INFOTAIMENT' : 'DIVERTISSEMENT',
      thumbnailUrl: "../../../assets/images/tiles/divertissement.png",
      routeUrl: '/home/infotainment',
      isOnline: false,
    },
    {
      label: this.hasEnCulture ? 'CHUM INFO' : 'INFO CHUM',
      thumbnailUrl: "../../../assets/images/tiles/info-CHUM.png",
      routeUrl: 'https://www.chumontreal.qc.ca/',
      isOnline: true,
    },
    {
      label: this.hasEnCulture ? 'HEALTH INFO' : 'INFO SANTÉ',
      thumbnailUrl: "../../../assets/images/tiles/info-sante.png",
      routeUrl: '/home/info-health',
      isOnline: false,
    }, {
      label: this.hasEnCulture ? 'MAKE A DONATION' : 'FAITES UN DON',
      thumbnailUrl: "../../../assets/images/tiles/faites-un-don.png",
      routeUrl: 'https://www.jedonneenligne.org/fondationduchum',
      isOnline: true,
    }, {
      label: this.hasEnCulture ? 'YOUR OPINION' : 'VOTRE OPINION',
      thumbnailUrl: "../../../assets/images/tiles/votre-opinion.png",
      routeUrl: 'https://sondage.chumontreal.qc.ca/',
      isOnline: true,
    },
    ]
  }

  gotoNotification() {
    if (this.loginUser) {
      this.notificationModal = this.nbDialogService.open(NotificationsComponent, { hasBackdrop: true, closeOnBackdropClick: false, autoFocus: false });
    }
    else
      this.router.navigate(['/home/landing']);
  }

  redirectToIptv() {
    this.commonService.setLSKey('pathnameUrl', window.location.pathname);
    this.router.navigate(['/home/infotainment/iptv']);
  }

  openRouteUrl(url: string, isOnline: boolean): void {
    if (isOnline)
      window.open(url, '_blank');
    else
      this.router.navigate([url]);
    // url = url.replace("facilityCode", facilityCode);
  }

  getCustomVideoList() {
    this.customVideoList = [{
      thumbnailUrl: `https://cronosshellstorage.blob.core.windows.net/movie-thumbnails/ParisCanWait.${this.hasEnCulture ? 'en' : 'fr'}.jpg`,
      title: this.hasEnCulture ? "Paris can wait" : "Paris can wait",
      description: this.hasEnCulture ? "Anne is at a crossroads in her life. Long married to a successful, driven but inattentive movie producer, she unexpectedly finds herself taking a car trip from Cannes to Paris with a business associate of her husband. What should be a seven-hour drive turns into a carefree two-day adventure replete with diversions involving picturesque sights, fine food and wine, humor, wisdom and romance..." : "Anne est une femme américaine qui est dans un mariage tendu avec un mari de producteur de film de bourreau de travail. Un jour, elle subitement se retrouve sur un road trip de Cannes à Paris avec un fringant associé de son mari. Ce qui aurait dû être à sept heures de route se transforme en une aventure de deux jours qui réveille la passion d'Anne pour la vie.",
      genre: " Comedy, Romance ",
      rating: "PG",
      routerUrl: `/home/video-viewer/${this.hasEnCulture ? '494118255' : '494118398'}/${this.hasEnCulture ? 'd2bc80d316ada5cd51afb3dc28d00557ab187019' : 'ac835c4377bfd438a6b621715f394d43bbf35c7e'}/165/false/0000/0000/false`,
    },
    {
      thumbnailUrl: `https://cronosshellstorage.blob.core.windows.net/movie-thumbnails/MeganLeavey.${this.hasEnCulture ? 'en' : 'fr'}.jpg`,
      title: this.hasEnCulture ? "Megan Leavey" : "Megan Leavey",
      description: this.hasEnCulture ? "Based on the true life story of a young marine corporal whose unique discipline and bond with her military combat dog saved many lives during their deployment in Iraq. When she is assigned to clean up the K9 unit after a disciplinary hearing, Leavey identifies with a particularly aggressive dog, Rex, and is given the chance to train him. Over the course of their service, Megan and Rex completed more than 100 missions ..." : "Le parcours du soldat Megan Leavey, chargée de repérer des explosifs avec Rex, un chien spécialement entrainé pour cette mission.",
      genre: 'Drama, Biography',
      rating: "PG",
      routerUrl: `/home/video-viewer/${this.hasEnCulture ? '494117955' : '494118159'}/${this.hasEnCulture ? "5d85586dae0d195d1d370ad7680ec125acbecf85" : "4fbba78ba9bda56444a83eff274d2a51cb578545"}/165/false/0000/0000/false`,
    },
    ]
  }
}
