
<div class="div-padding">
    <section class="pb-0" [ngClass]="isTileview?'pt-0':'section-top-padding'">
       <div class="container-fluid px-0">
          <div class="row no-gutters">
             <div class="col-12">
                <div class="gen-banner-movies banner-style-2 carousel">
                   <owl-carousel-o [options]="customOptions">
                      <ng-container *ngFor="let customVideo of customVideoList">
                         <ng-template carouselSlide>
                            <div class="item" [ngStyle]="{'background-image': 'url(' + customVideo.thumbnailUrl + ')'}">
                               <div class="gen-movie-contain-style-2 h-100">
                                  <div class="container h-100">
                                     <div class="row flex-row-reverse align-items-center h-100">
                                        <div class="col-xl-6">
                                           <div class="gen-front-image">
                                              <img class="carousel-image" [src]="customVideo.thumbnailUrl"
                                                 alt="owl-carousel-banner-image">
 
                                              <a [routerLink]="customVideo.routerUrl"
                                                 class="playBut popup-youtube popup-vimeo popup-gmaps">
                                                 <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  -->
                                                 <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="213.7px"
                                                    height="213.7px" viewBox="0 0 213.7 213.7"
                                                    enable-background="new 0 0 213.7 213.7" xml:space="preserve">
                                                    <polygon class="triangle" id="XMLID_17_" fill="none" stroke-width="7"
                                                       stroke-linecap="round" stroke-linejoin="round"
                                                       stroke-miterlimit="10" points="
                                                                 73.5,62.5 148.5,105.8 73.5,149.1 "></polygon>
                                                    <circle class="circle" id="XMLID_18_" fill="none" stroke-width="7"
                                                       stroke-linecap="round" stroke-linejoin="round"
                                                       stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3">
                                                    </circle>
                                                 </svg>
                                                 <span i18n="@@custom-video-card.watchTrailer">Watch Trailer</span>
                                              </a>
                                           </div>
                                        </div>
                                        <div class="col-xl-6">
                                           <div class="gen-tag-line"><span i18n="@@custom-video-card.newvideo">New Video</span>
                                           </div>
                                           <div class="gen-movie-info">
                                              <h3>{{customVideo.title}}</h3>
                                           </div>
                                           <div class="gen-movie-meta-holder">
                                              <ul class="gen-meta-after-title">
                                                 <li class="gen-sen-rating">
                                                    <span>
                                                       {{customVideo.rating}}</span>
                                                 </li>
                                                 <li><i aria-hidden="true" class="fas fa-hospital fa-2x"></i>
                                                 </li>
                                              </ul>
                                              <p>{{customVideo.description}}
                                              </p>
                                              <div class="gen-meta-info">
                                                 <ul class="gen-meta-after-excerpt">
                                                    <li>
                                                       <strong i18n="@@custom-video-card.genre">Genre :</strong>
                                                       <span>
                                                          <a href="javascript:void(0)"> {{customVideo.genre}}
                                                          </a>
                                                       </span>
                                                    </li>
                                                 </ul>
                                              </div>
                                           </div>
                                           <div class="gen-movie-action">
                                              <div class="gen-btn-container">
                                                 <a [routerLink]="customVideo.routerUrl"
                                                    class="gen-button .gen-button-dark">
                                                    <i aria-hidden="true" class="fas fa-play"></i> <span class="text"
                                                       i18n="@@custom-video-card.playNow">Play
                                                       Now</span>
                                                 </a>
                                              </div>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </ng-template>
                      </ng-container>
                   </owl-carousel-o>
                </div>
             </div>
          </div>
       </div>
    </section>
 </div>