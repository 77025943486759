import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, interval } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { I18nString } from '../data/i18nString';
import { ApiUrlService } from './api-url.service';
import { ConstantsService } from './constants.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { MsalService } from '@azure/msal-angular';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CommonService implements OnDestroy {
  protected loaderState$ = new BehaviorSubject(false);
  protected jsScriptState$ = new BehaviorSubject(true);
  protected headerState$ = new BehaviorSubject(true);
  protected footerState$ = new BehaviorSubject(true);
  public rebindStats = new BehaviorSubject<boolean>(true);
  public rebindPrescriptions = new BehaviorSubject<any>(null);
  public facilityChanges = new BehaviorSubject(false);
  public rebindScheduleMedia = new BehaviorSubject<any>(false);
  public orderItems = new BehaviorSubject<Array<any>>(Array<any>());
  public rentalDisplay = new BehaviorSubject(false);
  public closeBackGroundPopup = new BehaviorSubject(false);
  public closeBackgroundCardsPopup = new BehaviorSubject(false);
  public closeTermsPopup = new BehaviorSubject(false);
  public BindUserDetail = new BehaviorSubject(false);
  public rebindPlayList = new BehaviorSubject(false);
  public newRentalService = new BehaviorSubject(false);
  public modalPopup: any;
  public macAddress: string = '20174289e7f2';
  public genres: Array<any> = Array<any>();
  public isOpenHeaderDropdown = new BehaviorSubject(false);
  public forRentalHeaderMessage = new BehaviorSubject(false);
  public forShowAlertFacility = new BehaviorSubject(false);
  public hasAdvertisementPopup: boolean = false;
  public hasNotificationPopup: boolean = false;
  public facilityAdvertisement = new BehaviorSubject<Array<any>>(Array<any>());
  public isFreeIptvChannel: boolean = false;
  public customFacilityList: Array<any> = ["CHUM"];
  public isFreeFacilityFlag: boolean = false;
  public isCustomView: boolean = false;
  public facilityCurrentView: string = "WebView";
  public facilityLanguage: string = 'en';
  public isHopiflixPrescription: boolean = true;
  public isHopiflixCommunication: boolean = true;
  public customVideoFacilityList: Array<any> = ["MONCTON"];
  constructor(private constantsService: ConstantsService, private encryptDecryptService: EncryptDecryptService,
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: any, private urlServices: ApiUrlService, private authService: MsalService) {
    this.getGenres();
  }

  ngOnDestroy() { }


  changeLanguage(code: string): void {
    const enccode = this.encryptDecryptService.encryptData(code);
    localStorage.setItem('languageCode', enccode);
  }

  getLanguage(): string {
    let langCode = localStorage.getItem('languageCode');
    if (langCode) {
      const deccode = this.encryptDecryptService.decryptData(langCode);
      return deccode;
    }
    else {
      langCode = this.getDefaultLanguage();
      const enccode = this.encryptDecryptService.encryptData(langCode);
      localStorage.setItem('languageCode', enccode);
      return langCode;
    }
  }

  genresApi(): Observable<any> {
    const url = `${this.urlServices.genresMovieUrl}`;
    return this.httpClient.get<Array<any>>(url)
      .pipe(
        map((returnData: Array<any>) => {
          return returnData;
        }),
        catchError(this.handleError<Array<any>>('genresApi'))
      );
  }

  getGenres(): any {
    this.genresApi().subscribe(result => {
      this.genres = result;
    })
  }

  setGenresName(moviesData: any) {
    const movies = moviesData.map((movie: any) => {
      if (movie.genres) {
        const movieGenres = movie.genres.split(',').filter((genre: any) => genre);
        const genreNames = movieGenres?.map((movieGenre: any) => {
          const movieGenreId = parseInt(movieGenre.replace('(', ''));
          let genreName = this.genres?.find((x: any) => x.id == movieGenreId)?.name;
          return ' ' + genreName + ' ';
        });
        movie.genreNames = genreNames.toString();
      }
      return movie
    })
    return movies;
  }

  getLanguages(): any[] {
    return this.constantsService.language;
  }

  getLanguageText(code: string): string {
    return this.constantsService.language.filter(x => x.Key === code)[0].Value;
  }

  getDefaultLanguage(): string {
    return this.constantsService.language.filter(x => x.IsDefault === true)[0].Key;
  }

  setLSKey(key: string, value: any): void {
    const result = this.encryptDecryptService.encryptData(value);
    localStorage.setItem(key, result);
  }

  getLSKey(key: string): any {
    let result = '';
    const value = localStorage.getItem(key);
    if (!(value == null || value == undefined)) {
      result = this.encryptDecryptService.decryptData(value);
    }
    return result;
  }

  deleteLSKey(key: string): void {
    localStorage.removeItem(key);
  }

  clearLocalStorage(): void {
    localStorage.clear();
  }

  setLoaderState(state: boolean = false): any {
    this.loaderState$.next(state);
  }

  onLoaderState(): Observable<any> {
    return this.loaderState$.asObservable();
  }

  setJsScriptState(state: boolean = true): any {
    this.jsScriptState$.next(state);
  }

  onJsScriptState(): Observable<any> {
    return this.jsScriptState$.asObservable();
  }

  setHeaderState(state: boolean = true): any {
    this.headerState$.next(state);
  }

  onHeaderState(): Observable<any> {
    return this.headerState$.asObservable();
  }

  setFooterState(state: boolean = true): any {
    this.footerState$.next(state);
  }

  onFooterState(): Observable<any> {
    return this.footerState$.asObservable();
  }

  checkRentedService(serviceCode: string): Observable<any> {
    const facilityId = this.getLSKey("ceFacilityId");
    const email = this.authService.instance.getAllAccounts()[0].username;
    const url = `${this.urlServices.serviceUrl}/${facilityId}/rented-services/${serviceCode}/byod-customers/${email}`;
    return this.httpClient.get<any>(url, { observe: 'response' })
      .pipe(catchError(this.handleError<any>(`checkRentedService url=${url}`)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getI18nString(title: I18nString[]): string {
    const isFrench = this.getLanguage() == 'fr' ? true : false;
    const langToShow = (isFrench ? "fr" : "en")
    let textToReturn = "";
    for (let i = 0; i < title?.length; i++) {
      if (title[i].languageCode.toLocaleLowerCase() == langToShow)
        textToReturn = title[i].text
    }
    if (textToReturn == "")
      textToReturn = title[0].text
    return textToReturn;
  }

  defaultImage() {
    return '../../../../assets/images/logos/mediastudio_logo.png';
  }

  toHHMMSS(sec_num: number) {
    let hours: any = Math.floor(sec_num / 3600);
    let minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds: any = sec_num - (hours * 3600) - (minutes * 60);
    seconds = Math.round(seconds * 100) / 100;

    if (hours < 1) { hours = "0" + hours; }
    if (minutes < 1) { minutes = "0" + minutes; }
    if (seconds < 1) { seconds = "0" + seconds; }
    const h = hours > 0 ? hours + 'hr ' + ' ' + minutes + 'min ' + ' ' + seconds + 'sec' : minutes + 'min' + ' ' + seconds + 'sec';
    return h;
  }

  CountPrescriptions() {
    let totalUnseenPrescription = 0;
    let lsPrescriptions = this.getLSKey("Prescrption");
    if (lsPrescriptions != '' && lsPrescriptions != undefined) {
      const prescrptionList = JSON.parse(lsPrescriptions);
      for (let i = 0; i < prescrptionList.length; i++) {
        const prescription = prescrptionList[i];
        for (let j = 0; j < prescription.prescriptionItems.length; j++) {
          const item = prescription.prescriptionItems[j];
          if (item.media && !item.mediaWatched) {
            totalUnseenPrescription = totalUnseenPrescription + 1;
          }
          else if (item.media && item.mediaWatched && item.followUpQuestionnaire && !item.followUpAnswered) {
            totalUnseenPrescription = totalUnseenPrescription + 1;
          }
          else if (item.survey && !item.surveyAnswered) {
            totalUnseenPrescription = totalUnseenPrescription + 1;
          }
          else if (item.document && item.documentRead && item.documentFollowUpQuestionnaire && !item.documentFollowUpAnswered) {
            totalUnseenPrescription = totalUnseenPrescription + 1;
          }
          else if (item.document && !item.documentRead) {
            totalUnseenPrescription = totalUnseenPrescription + 1;
          }
        }
      }
    }
    return {
      totalUnseenPrescription: totalUnseenPrescription,
    }
  }

  getClientIp(): Observable<any> {
    return this.httpClient.get<any>('https://jsonip.com/')
      .pipe(
        map((returnData: Response) => {
          return returnData;
        }),
      )
  }

  closePopup() {
    this.modalPopup?.close();
  }

  getUserView() {
    let isTileview: boolean = false;
    const value = localStorage.getItem('isTileview');
    if (value)
      isTileview = this.encryptDecryptService.decryptData(value);
    return isTileview;
  }

  exitFullscreen() {
    var isInFullScreen = (this.document?.fullscreenElement && this.document?.fullscreenElement !== null) ||
      (this.document?.webkitFullscreenElement && this.document?.webkitFullscreenElement !== null) ||
      (this.document?.mozFullScreenElement && this.document?.mozFullScreenElement !== null) ||
      (this.document?.msFullscreenElement && this.document?.msFullscreenElement !== null);
    if (isInFullScreen) {
      if (this.document?.exitFullscreen)
        this.document?.exitFullscreen();
      else if (this.document?.webkitExitFullscreen)  /* Safari */
        this.document?.webkitExitFullscreen();
      else if (this.document?.mozCancelFullScreen)
        this.document?.mozCancelFullScreen();
      else if (this.document?.msExitFullscreen)  /* IE11 */
        this.document?.msExitFullscreen();
    }
  }


}

